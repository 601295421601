/**
 * 管理者勤務表画面からの出退勤実績登録・更新用
 */
export default class AttendanceFix {
  recordId: string;
  employeeId: string;
  affiliationId: string;
  targetShiftId: string;
  shiftpatternid: string;
  attendanceDate: string;
  startTime: string;
  endTime: string;
  movingDistance: string;
  breaktime: string;
  morningbreaktime: string;
  midnightbreaktime: string;
  work: string;
  over: string;
  morning: string;
  midnight: string;
  midnightover: string;
  inover: string;
  leaveTime: string;
  lateTime: string;
  lateleave: string;
  hadmeal: string;
  workclassificationid: string;
  amount: string;
  reserveAmount1: number;
  reserveAmount2: number;
  reserveid1: string;
  nightShift: string;
  careShu: string;
  careKbn: string;
  timeKbn: string;
  nightGuard: string;
  emergencyFlag: string;
  midnightTel: string;
  assistFlag: string;
  otherFlag: string;
  absence: string;
  overtimeflag: string;
  biko: string;
  opeTime: string;
  constructor(props: {
    recordId: string;
    employeeId: string;
    affiliationId: string;
    targetShiftId: string;
    shiftpatternid: string;
    attendanceDate: string;
    startTime: string;
    endTime: string;
    movingDistance: string;
    breaktime: string;
    morningbreaktime: string;
    midnightbreaktime: string;
    work: string;
    over: string;
    morning: string;
    midnight: string;
    midnightover: string;
    inover: string;
    leaveTime: string;
    lateTime: string;
    lateleave: string;
    hadmeal: string;
    workclassificationid: string;
    amount: string;
    reserveAmount1: number;
    reserveAmount2: number;
    reserveid1: string;
    nightShift: string;
    careShu: string;
    careKbn: string;
    timeKbn: string;
    nightGuard: string;
    emergencyFlag: string;
    midnightTel: string;
    assistFlag: string;
    otherFlag: string;
    absence: string;
    overtimeflag: string;
    biko: string;
    opeTime: string;
  }) {
    this.recordId = props.recordId;
    this.employeeId = props.employeeId;
    this.affiliationId = props.affiliationId;
    this.targetShiftId = props.targetShiftId;
    this.shiftpatternid = props.shiftpatternid;
    this.attendanceDate = props.attendanceDate;
    this.startTime = props.startTime;
    this.endTime = props.endTime;
    this.movingDistance = props.movingDistance;
    this.breaktime = props.breaktime;
    this.morningbreaktime = props.morningbreaktime;
    this.midnightbreaktime = props.midnightbreaktime;
    this.work = props.work;
    this.over = props.over;
    this.morning = props.morning;
    this.midnight = props.midnight;
    this.midnightover = props.midnightover;
    this.inover = props.inover;
    this.leaveTime = props.leaveTime;
    this.lateTime = props.lateTime;
    this.lateleave = props.lateleave;
    this.hadmeal = props.hadmeal;
    this.workclassificationid = props.workclassificationid;
    this.amount = props.amount;
    this.reserveAmount1 = props.reserveAmount1;
    this.reserveAmount2 = props.reserveAmount2;
    this.reserveid1 = props.reserveid1;
    this.nightShift = props.nightShift;
    this.careShu = props.careShu;
    this.careKbn = props.careKbn;
    this.timeKbn = props.timeKbn;
    this.nightGuard = props.nightGuard;
    this.emergencyFlag = props.emergencyFlag;
    this.midnightTel = props.midnightTel;
    this.assistFlag = props.assistFlag;
    this.otherFlag = props.otherFlag;
    this.absence = props.absence;
    this.overtimeflag = props.overtimeflag;
    this.biko = props.biko;
    this.opeTime = props.opeTime;
  }
}
