var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('v-app-bar',{staticClass:"no-print",attrs:{"color":"indigo darken-1","dark":"","clipped-left":"","app":""}},[_c('v-toolbar-title',[_vm._v(_vm._s("休暇申請"))]),_c('v-spacer'),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"pink darken-1","text-color":"white"},on:{"click":_vm.userTop}},[[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1),_vm._v(" 戻る ")]],2),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"lime darken-4","text-color":"white"},on:{"click":_vm.logout}},[[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-account")])],1),_vm._v(" "+_vm._s(_vm.$store.state.name)+" ")]],2)],1),_c('v-main',[_c('v-container',[_c('div',[_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"text-h5"},[_vm._v("休暇申請一覧")]),_c('div',{staticClass:"d-flex"},[_c('DatePicker',{attrs:{"label":"休暇取得日From","prepend-inner-icon":"mdi-calendar","type":"day","outlined":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('div',{staticClass:"d-flex"},[_c('DatePicker',{attrs:{"label":"休暇取得日To","prepend-inner-icon":"mdi-calendar","type":"day","outlined":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('div',{staticClass:"d-flex"},[_c('Btn',{staticClass:"ml-4",attrs:{"color":"info","icon":"mdi-table-search"},on:{"click":_vm.getData}},[_vm._v("検索")]),_c('v-spacer'),_c('Btn',{staticClass:"ml-4",attrs:{"color":"error","icon":"mdi-calendar-plus"},on:{"click":function($event){return _vm.onAdd(0)}}},[_vm._v("新規申請")])],1)]),_c('hr'),_c('div',{staticClass:"mt-5"},[(_vm.rows.length)?_c('vue-good-table',{attrs:{"columns":_vm.cols,"rows":_vm.rows,"sort-options":{
              enabled: false
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 30,
              perPageDropdown: [5, 10, 30],
              dropdownAllowAll: true,
              setCurrentPage: 1,
              nextLabel: '前',
              prevLabel: '次',
              rowsPerPageLabel: '1ページ表示件数',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All'
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'remarks')?_c('span',_vm._l((props.row.remarks),function(remark){return _c('v-chip',{key:remark,staticClass:"mx-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(remark)+" ")])}),1):_vm._e(),_c('span',[_c('Btn',{attrs:{"color":"info","text":"","small":""},on:{"click":function($event){return _vm.onEdit(props.row.recordId)}}},[_vm._v("照会")])],1)]}}],null,false,3050140695)}):_c('p',[_vm._v("該当する休暇申請が存在しません")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }